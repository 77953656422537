#home {
  position: relative;
  background: linear-gradient(#1c1c1e, #2c2c3c);

  background-size: cover;
  background-repeat: repeat;
  background-position: center;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 6rem 2rem 0;
  @media screen and (min-width: 200px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 8rem;
    flex-direction: column;
  }
  @media screen and (max-width: 400px) {
    padding-top: 8rem;
    padding: 6rem 1rem 2rem;
  }
}
.hobby_flex {
  @media screen and (max-width: 900px) {
    display: flex !important;
    flex-direction: row !important;
  }

}
.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}
.app__header-badge {
  width: 100%;
  display: flex;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: linear-gradient(#1c1c1e, #2c2c3c);
    border-radius: 15px;
    width: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;
    font-weight: bold;
    p {
      width: 100%;
      text-transform: uppercase;
      text-align: left;
      text-spacing-trim: space-all;
    }
    @media screen and (max-width: 900px) {
      margin-top: 1rem;
    }
  }
  span {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.hobby {
  align-items: center;
  justify-content: center !important;
}

.app__header-circles {
  flex: 0.75;
  margin-left: 1rem;
  margin-top: 20rem;
  height: 100%;
  color: lightgray;
  p {
    color: lightgray;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    div {
      margin: 1rem;
    }
  }
}
.app__header-img {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  }
  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }
  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
}
