.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.app__work-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
h2 {
  margin: 2rem;
}
.work-container {
  padding: 3rem;
  border-radius: 5px;
  background: linear-gradient(#1C1C1E, #2C2C3C);;
  box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.6);
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  font-size: 10px;
}
.work-title p {
  color: lightgray;
  padding: 1.5rem 0;
  text-align: center;
  display: flex;
  align-items: center;
}
.work-desc p{
  font-size: 1rem;
  font-weight: normal !important;
  color: #b2b2b2;
}
.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #1b23b2;
    top: -25px;
  }
}
